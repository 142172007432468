import React, { useEffect, useState } from "react";

import PurchaseAndHiringDetails from './PurchaseAndHiringDetails'
import PurchaseWithApportionmentDetails from './PurchaseWithApportionmentDetails'

import { useParams } from "react-router-dom";

import { useRequest } from "../../../repositories";

const RequestDetail = () => {

  const repository = useRequest();

  const [requestData, setRequestData] = useState<any>();

  const { id } = useParams();


  const loadData = async () => {

    const requestResponse = await repository.findRequestById(id, {});

    setRequestData(requestResponse)
  };

  useEffect(() => {
    loadData();
  }, []);


  return (

    requestData?.typeRequest ?
      requestData?.typeRequest === 'SIMPLE' ?
      <PurchaseAndHiringDetails />
      :
      <PurchaseWithApportionmentDetails />
    :
    <></>
  );
};

export default RequestDetail;
