import React, {useEffect, useState} from "react";
import { Table, Button} from "antd";

import {useApprovalHierarchy} from "../../../repositories";
import moment from "moment/moment";
import ModalChangeHistory from "./ModalChangeHistory";


const TableHistory = (props: any) => {
    const { hierarchy_id } = props;
    const [history, setHistory] = useState([]);
    const [isVisibleModalHistory, setIsVisibleModalHistory] = useState(false);
    const [record, setRecord] = useState<any>();
    const repository = useApprovalHierarchy();
    useEffect(() => {}, []);

    useEffect(() => {
        const getlogs = async () => {

            const response = await repository.findLogs(hierarchy_id);

            if (!response) return;

            const mappedData = response.content.map((item: any) => {
                return {
                    id: item.id,
                    createdDateFormat: moment(item.createdDate).format("DD/MM/YYYY HH:mm"),
                    responsibleManager: item.userName,
                    ...item
                }
            }).sort((a: any, b: any) => moment(b.createdDate).diff(moment(a.createdDate)));
            setHistory(mappedData)
        };



        getlogs();
    }, []);

    const onClickModalHisrory = (record: any) => () => {
        setRecord(record)
        setIsVisibleModalHistory(true);
    };

    return (
        <>
            <Table
                pagination={false}
                dataSource={history}
                columns={[
                    {
                        title: "Id",
                        dataIndex: "id",
                        key: "id",
                    },
                    {
                        title: "Responsável",
                        dataIndex: "responsibleManager",
                        key: "responsibleManager",
                    },
                    {
                        title: "Data de edição",
                        dataIndex: "createdDateFormat",
                        key: "createdDateFormat",
                    },
                    {
                        title: "",
                        dataIndex: "view",
                        key: "visualizar",
                        render(_: any, record: any) {
                            return (
                                <Button
                                    onClick={onClickModalHisrory(record)}
                                    style={{ cursor: "pointer" }}
                                >
                          Visualizar
                        </Button>
                            );
                        },
                    },
                ]}
            />
            <ModalChangeHistory
                isVisible={isVisibleModalHistory}
                setIsVisible={setIsVisibleModalHistory}
                record={record}
            />
        </>
    );
};

export default TableHistory;
