import React, { useEffect, useState } from "react";
import type { Rule } from "antd/lib/form";
import {
  TApprovalHierarchyForm,
  useApprovalHierarchy,
  useBusinessUnits,
} from "repositories";
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  SearchField, AreaField,
} from "components";
import { Navigate, useNavigate, useParams } from "react-router";
import {
  Button,
  Form,
  message,
  Space,
  Radio,
  Checkbox,
  Table,
  Upload, Divider,
} from "antd";
import { useAuth } from "hooks";
import ApproverList from "../../Components/ApproverList";
import {CloudUploadOutlined} from "@ant-design/icons";
import {UploadWrapper} from "./styles"
import ModalChangeHistory from "../../Components/ModalChangeHistory";
import TableHistory from "../../Components/TableHistory";

const { Dragger } = Upload;

const initialValues: TApprovalHierarchyForm = {
  id: "",
  requestId: "",
  name: "",
  businessUnits: {},
  timing: "",
  reference: false,
  categoryRequest: [],
  typeRequest: "",
  typeProvider: "",
  approvers: [],
  approversReference: [],
};

const validationForm = (data: any) => {
  let ceo = false;
  let conselho = false;
  if(data.bu === 0){
    message.error(buMessageError);
    return false;
  }
  if(data.hierarchyUsers.length === 0){
    message.error(hierarchyUsersEmptyMessageError);
    return false;
  }
  if(data.hierarchyUsers.length === 1){
    message.error(hierarchyUsersOnlyOneMessageError);
    return false;
  }
  for (const item of data.approvers) {
    if (item.userName === "CEO") {
      ceo = true;
    }
    if (item.userName === "Conselho") {
      conselho = true;
    }
    if (conselho && ceo) {
      break;
    }
  }
  if (conselho && ceo) {

  } else if (ceo) {
    message.error(onlyCEOOrOnlyConselho);
    return  false
  } else if (conselho) {
    message.error(onlyCEOOrOnlyConselho);
    return  false
  } else {
    message.error(onlyCEOOrOnlyConselho);
    return  false
  }
  if(data.approvers.length < 3){
    message.error(hierarchyUsersOnlyOneApproverMessageError);
    return false;
  }

  return true;
}

const validations: Record<string, Rule[]> = {
  name: [
    {
      required: true,
      message: 'Campo "Nome" é obrigatório',
    },
    {
      max: 150,
      message: "Nome não pode ser maior que 150 caracteres",
    },
  ],
  responsibleManager: [
    {
      required: true,
      message: 'Campo "Gestor" é obrigatório',
    },
    {
      max: 150,
      message: "Gestor não pode ser maior que 150 caracteres",
    },
  ],
};

const updateMessageSuccess = "Alçada atualizada com sucesso!";
const buMessageError = "Unidade de negocio não selecionada!";
const hierarchyUsersEmptyMessageError = "É obrigatório informar os aprovadores!";
const hierarchyUsersOnlyOneMessageError = "O CEO e Conselho são obrigatórios!";
const hierarchyUsersOnlyOneApproverMessageError = "É obrigatório ao menos um aprovador além do CEO e Conselho";
const onlyCEOOrOnlyConselho = "O CEO e Conselho são obrigatórios.";

const ApprovalHierarchyEditionForm = () => {
  const [approvalHierarchy, setApprovalHierarchy] =
    useState<TApprovalHierarchyForm>({} as TApprovalHierarchyForm);

  const { roles: authRoles, hasRole } = useAuth();
  const navigate = useNavigate();
  const { id: IdApprovalHierarchy } = useParams<{ id: string }>();
  const repository = useApprovalHierarchy();
  const buRepository = useBusinessUnits();
  const [form] = Form.useForm<TApprovalHierarchyForm>();
  const [filesItems, setFilesItems] = useState<any>([]);
  const [approvers, setApprovers] = useState<any>([]);
  const [typeRequest, setTypeRequest] = useState(1);
  const [isVisibleModalChangeHistory, setIsVisibleModalChangeHistory] =
      useState(false);
  const [timing, setTiming] = useState("");
  const [bus, setBUs] = useState([]);
  const [optionsBU, setBUOptions] = useState([]);
  const [isReference, setIsReference] = useState(false);

  const [bu, setBu] = useState<any>();
  const [value, setValue] = useState<any>();
  const [BuName, setBuName] = useState("");

  const onChangeBU = (newValue: any) => {
    const _bu = bus
      .map((bu: any) => ({ id: bu.id, name: bu.name }))
      .filter((bu: any) => bu.name === newValue);
    setBu(_bu[0].id);
  };

  const onSearchBU = async (searchText: string) => {
    const response = await buRepository.findBus({
      name: searchText,
      page: 0,
      size: 10,
    });
    if (!response) return;

    const mapped = response.content.map((item: any) => ({ value: item.name }));
    setBUOptions(mapped);
    setBUs(response.content);
  };


  useEffect(() => {
    const getApprovalHierarchyById = async () => {
      const response = await repository.findByIdApprovalHierarchy(
        Number(IdApprovalHierarchy)
      );
      if (!response) return;
      const approvers = response.approvers.map((approver, index) => ({
        ...approver,
        localId: index + 1,
      }));
      const approversReference = response.approversReference.map(
        (approver, index) => ({
          ...approver,
          localId: index + 1,
        })
      );

      const {
        id,
        name,
        timing,
        businessUnits,
        requestId,
        typeProvider,
        typeRequest,
        reference,
        categoryRequest,
      } = response;

      const data = {
        id,
        name,
        timing,
        businessUnits,
        typeProvider,
        typeRequest,
        reference,
        categoryRequest,
        requestId: requestId ? response.requestId : "",
        selectBu: businessUnits.name,
        approvers,
        approversReference,
      };

      form.setFieldsValue(data);

      setIsReference(data.reference);
      setTiming(data.timing);
      setValue(response.businessUnits.id);
      setBuName(response.businessUnits.name);
      setApprovalHierarchy(data);
    };


    form.resetFields();
    if (IdApprovalHierarchy) {
      getApprovalHierarchyById();
    }
  }, [IdApprovalHierarchy]);
  const onFinish = async (values: any) => {

    const formData = new FormData();
    const hierarchyUsers = approvers.map((item: any) => {
      const sanitizedItem = { ...item };
      delete sanitizedItem.userName;
      return sanitizedItem
    })

    const data = {
      bu: value,
      hierarchyUsers,
      approvers
    }

    if(!validationForm(data)) return ;

    formData.append("id", String(IdApprovalHierarchy));
    formData.append("name", values.name);
    formData.append("businessUnits", value);
    formData.append("timing", timing);
    formData.append("reference", String(isReference));
    formData.append("categoryRequest", "['Compras e contratações']");
    formData.append("typeRequest", typeRequest === 1 ? "SIMPLE" : "COMPOSED");
    formData.append("justifyEdit", values.justifyEdit);
    formData.append("responsibleManager", values.responsibleManager);
    formData.append("hierarchyUsers", JSON.stringify(hierarchyUsers));

    for (let i = 0; i < filesItems.length; i++) {
      formData.append("files", filesItems[i]);
    }
    // const headers = {
    //   "content-type": "multipart/form-data",
    // };
    let responseFormData
    try {
      responseFormData = await repository.updateApprovalHierarchyFormData(
          formData,
          // headers
      );
    } catch (error) {
    }
    if (!responseFormData) return;

    if(responseFormData.id > 0){
      message.success(updateMessageSuccess);
      onClickGoBack();
    }
  };

  const onClickGoBack = () => {
    navigate("/alcadas");
  };

  if (
    authRoles &&
    !hasRole(
      authRoles?.approvalhierarchy_edit || authRoles?.approvalhierarchy_view
    )
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  const onChangeTypeRequest = (e: any) => {
    setTypeRequest(e.target.value);
  };

  const onChangeTiming = (e: any) => {
    setTiming(e.target.value);
  };

  const onChangeIsReference = (e: any) => {
    setIsReference(e.target.checked);
  };


  const secondOptions = [
    { label: "Pacote", value: 11 },
    { label: "Roteiro", value: 22 },
  ];
  const props = {
    name: "file",
    multiple: true,
    action: "",
    customRequest: (file: any) => {
      setFilesItems([...filesItems, file.file]);
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const removeFile = (file: any) => {
    const index = filesItems.findIndex((obj: any) => obj.uid === file.uid);
    const newArray = [...filesItems];
    if (index !== -1) {
      newArray.splice(index, 1);
      setFilesItems(newArray);
    }
  };
  return (
    <>
      <PageHeader
        title={`Editar Alçada de Aprovação`}
        breadcrumbItems={["Alçadas", "Editar"]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={12} xxl={12}>
              <TextField required disabled name="id" label="Id" />
            </Col>
            <Col xl={12} xxl={12}>
              <TextField
                required
                disabled
                name="requestId"
                label="ID da requisição"
              />
            </Col>
          </Row>
          <Row>
            <Col xl={14}>
              <TextField
                required
                rules={validations.name}
                name="name"
                label="Nome"
              />
            </Col>
            <Col xl={14}>
              <Checkbox
                name="reference"
                disabled
                onChange={onChangeIsReference}
                checked={isReference}
              />
              Alçada de Referência
            </Col>
          </Row>
          <Row>
            <Col xl={24}>
              <Form.Item>
                Parâmetros para configuração de alçada de aprovação de
                requisições
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeTypeRequest}
                  value={typeRequest}
                >
                  <Radio value={1}>Requisições Simples</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={1}>
              <></>
            </Col>
            <Col xl={23}>
              <Form.Item>
                {typeRequest === 1 ? (
                  <Radio.Group
                    value={1}
                  >
                    <Radio value={1}>Compras e contratações</Radio>
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    options={secondOptions}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  onChange={onChangeTiming}
                  value={timing}
                >
                  <Radio value="PLANEJADA">Planejada</Radio>
                  <Radio value="EMERGENCIAL">Não planejada</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Radio.Group
                  value={1}
                >
                  <Radio value={1}>Atrelada a projeto</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <SearchField
                onSearch={onSearchBU}
                onChange={onChangeBU}
                required
                allowClear
                name="selectBu"
                placeholder="Selecione uma unidade de negócio"
                options={optionsBU}
                value={BuName}
              />
            </Col>
          </Row>
          <ApproverList
            initialApprovers={approvalHierarchy.approvers}
            setApprovers={setApprovers}
            approvers={approvers}
          />
          <Row>
            <Col xl={14}>
              <TextField
                  required
                  rules={validations.responsibleManager}
                  name="responsibleManager"
                  label="Qual foi o gestor que autorizou a alteração desta alçada?"
              />
            </Col>
            <Col xl={24}>
              <AreaField
                  required
                  name="justifyEdit"
                  maxLength={-1}
                  label="Justifique a necessidade de edição desta alçada de referência."
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
              />
            </Col>
            <Col xl={24}>
              <UploadWrapper>
                <Dragger {...props} showUploadList={false}>
                  <div
                      style={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                  >
                    <CloudUploadOutlined
                        style={{ fontSize: "24px", marginTop: "-5px" }}
                    />
                    <p className="ant-upload-text">
                      Clique ou arraste para realizar o upload
                    </p>
                  </div>
                </Dragger>
              </UploadWrapper>
            </Col>
            <Col>
              <div></div>
            </Col>
            <Col xl={24}>
              <Table
                  pagination={false}
                  dataSource={filesItems}
                  columns={[
                    {
                      title: "Nome do arquivo",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Formato",
                      dataIndex: "type",
                      key: "type",
                    },
                    {
                      title: "",
                      dataIndex: "delete",
                      key: "delete",
                      render(_: any, record: any) {
                        return (
                            <span
                                onClick={() => removeFile(record)}
                                style={{ cursor: "pointer" }}
                            >
                          excluir
                        </span>
                        );
                      },
                    },
                  ]}
              />
            </Col>
          </Row>
          <br/>
          <Divider plain orientation='left'>Histórico de edições dessa alçada de referência</Divider>
          <TableHistory
              hierarchy_id={IdApprovalHierarchy}
              isVisible={true}
          />
          <Form.Item className="mt-1">
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  );
};

export default ApprovalHierarchyEditionForm;
