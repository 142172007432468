import React, { useState, useEffect } from "react";
import { v4 as genId } from "uuid";
import { useAuth } from "hooks/useAuth";
import { Chatbox } from "./styles";
import ChatRequisition from "../ChatRequisition";

const Modal = (props: any) => {
  const { order, updateList, layoutApproval } = props;
  const [groupMembersChat, setGroupMembersChat] = useState<any>([]);
  const { userData } = useAuth();

  const getMembers = () => {
    const userLogged = {
      key: genId(),
      userId: userData?.userId,
      userName: userData?.userName,
    };

    const approvers = order?.approvers?.map((approver: any) => ({
      key: genId(),
      userId: approver.userId,
      userName: approver.userName,
    }));

    const requester = {
      key: genId(),
      userId: order?.creationUserId,
      userName: order?.creationUserName,
    };

    const allUsers = [
      userLogged,
      ...approvers,
      requester
    ];

    const uniqueUsers = allUsers.filter((user, index, self) => 
      index === self.findIndex((t) => t.userId === user.userId)
    );

    setGroupMembersChat(uniqueUsers)
  };

  useEffect(() => {
    getMembers();
  }, [order]);

  return (
    <Chatbox>
      <ChatRequisition
        groupMembers={groupMembersChat}
        order={order}
        updateList={updateList}
        layoutApproval={layoutApproval ?? false}
      />
    </Chatbox>
  );
};

export default Modal;
