import { AxiosResponse } from 'axios'
import {
  ApiErrorCodes,
  ApiErrorMessages
} from 'utils/constants'
import { message as toast } from 'antd'
import { useAuth } from './useAuth'

const errorsMessage = new Map<string, number>()

const useErrorHandler = () => {
  const { removeUserData, userData } = useAuth()

  const returnMessage = (message: string) => {
    let messageDisplayedAt = errorsMessage.get(message)
    if (!messageDisplayedAt) messageDisplayedAt = 0

    const present = new Date().getTime()
    const elapsedTime = (present - messageDisplayedAt) / 1000

    if (elapsedTime > 4) {
      errorsMessage.set(message, present)

      errorsMessage.forEach((value, key) => {
        if ((present - value) / 1000 >= 4) errorsMessage.delete(key)
      })

      return true
    }
    return false
  }

  const handleApiError = (error: string) => {
    const message = getErrorMessages(error)
    // resolução paliativa do retorno da rota product-price-range
    if (message.length > 0 && returnMessage(message)) {
      toast.error(message)
    }
  }

  const handleApiErrorDesambiguation = (response: AxiosResponse) => {
    if (!response) {
      handleApiError('')
      return
    }

    const { data } = response

    if (data.error_description === 'Session not active') {
      getErrorMessages(response.status)
      // Resolução paliativa do problema de duplicação de mensagem de erro ao estar deslogado
      // handleInvalidTokenError()
      return
    }

    if (response.status === ApiErrorCodes.UNAUTHORIZED && userData) {
      removeUserData()
      getErrorMessages(response.status)
      // Resolução paliativa do problema de duplicação de mensagem de erro ao estar deslogado
      // handleInvalidTokenError()
      return
    }

    if (data.error_description) {
      handleApiError(response.data.error_description)
      return
    }

    handleApiError(response.data.message)
  }

  const getErrorMessages = (message: any) => {
    switch (message) {
      case ApiErrorMessages.ACCOUNT_IS_NOT_FULLY_SET_UP:
        return 'A configuração da conta deste usuário não está completa (Keycloak).'
      case ApiErrorMessages.INVALID_USER_CREDENTIALS:
        return 'Usuário e/ou senha inválidos.'
      case ApiErrorMessages.INVALID_CLIENT_CREDENTIALS:
        return 'Credenciais de cliente inválidas (Keycloak).'
      case ApiErrorMessages.USER_DISABLED:
        return 'O usuário esta inativo.'
      case ApiErrorMessages.UNABLE_TO_LINK_USER_TO_INACTIVE_GROUP:
        return 'Não é possivel vincular um grupo inativo a um usuário.'
      case ApiErrorMessages.UNABLE_DISABLE_GROUP_HAS_USER_LINKED:
        return 'Não é possivel inativar o grupo com usuário vinculad.'
      case ApiErrorMessages.USER_GROUP_ALREADY_REGISTERED:
        return 'Já existe um grupo com esse nome cadastrado.'
      case ApiErrorMessages.SUPPLIER_ALREADY_REGISTERED_CNPJ:
        return 'Já existe um fornecedor cadastrado com esse CNPJ'
      case ApiErrorMessages.CEP_NOT_FOUND_BRASIL_API:
        return 'Não foi encontrado nenhum endereço para o CEP digitado.'
      case ApiErrorMessages.INVALID_PASSWORD:
        return 'Senha informada não está válida.'
      case ApiErrorMessages.NOT_FOUND_SUPPLIER:
        return 'Fornecedor não encontrado.'
      case ApiErrorMessages.EXPIRED_PASSWORD:
        return 'Senha informada encontra-se expirada.'
      case ApiErrorMessages.ADDRESS_ALREADY_LINKED_TO_SUPPLIER:
        return 'O endereço informado já encontra-se vinculado a este fornecedor.'
      case ApiErrorMessages.EMAIL_FOR_SUPPLIER_ALREADY_REGISTERED:
        return 'O email informado já está vinculado a outro fornecedor.'
      case ApiErrorMessages.ACCOUNT_BANK_ALREADY_LINKED_TO_SUPPLIER:
        return 'A conta bancaria informada já encontra-se vinculada a este fornecedor.'
      case ApiErrorMessages.APPROVAL_HIERARCHY_ALREADY_REGISTERED_WITH_NAME:
        return 'Já existe uma alçada cadastrada com esse nome.'
      case ApiErrorMessages.PRODUCT_WITH_ERP_ID_ALREADY_REGISTERED:
        return 'Já existe um produto cadastrado com esse código ERP.'
      case ApiErrorMessages.USER_ALREADY_REGISTERED_FOR_SUPPLIER:
        return 'Usuário já vinculado a este fornecedor.'
      case ApiErrorMessages.UNABLE_LINK_PRODUCTS_WITH_DIFFERENT_CATEGORIES:
        return 'Não foi possível vincular produtos com categorias diferentes na mesma solicitação!'
      case ApiErrorMessages.APPROVER_ALREADY_REGISTERED_FOR_HIERARCHY:
        return 'Usuário já encontra-se vinculado a lista de aprovadores!'
      case ApiErrorMessages.THE_PERCENTAGE_SUM_IS_NOT_100:
        return 'A soma do percentual não é de 100%'
      case ApiErrorMessages.APPROVER_ANSWER_ALREADY_INFORMED_THIS_HIERARCHY_USER:
        return 'Aprovador já respondeu uma requisição por esta alçada!'
      case ApiErrorMessages.PURCHASE_ORDER_ITEMS_SUM_IS_NOT_EQUALS_PURCHASE_TOTAL_VALUE:
        return 'A soma dos itens do pedido de compra não é igual ao valor total da compra!'
      case ApiErrorMessages.ERR_TOTVS_SERVICE_INSTANCE_CANNOT_BE_NULL:
        return 'Erro ao enviar para o TOTVS: Service Instance cannot be null'
      case ApiErrorMessages.APPROVAL_HIERARCHY_ALREADY_EXIST:
        return 'Alçada já criada'
      // Resolução paliativa do problema de duplicação de mensagem de erro ao estar deslogado
      case 'Session not active':
        return 'Sua sessão foi encerrada. Por favor, faça login novamente!'
      // resolução paliativa do retorno da rota product-price-range
      case 'Product price range not found!':
        return ''
      // Resolução paliativa do problema de duplicação de mensagem de erro ao estar deslogado
      case ApiErrorCodes.UNAUTHORIZED && userData:
        return 'Sua sessão foi encerrada. Por favor, faça login novamente!'
      case ApiErrorMessages.SUPPLIER_BLOCK:
        return 'O CNPJ encontra-se bloqueado para o sistema.'
      case ApiErrorMessages.REQUEST_VALUE_ZERO:
        return 'Valor da requisição não pode ser zero.'
      default:
        return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde.'
    }
  }

  return {
    handleApiError,
    handleApiErrorDesambiguation
  }
}

export default useErrorHandler
