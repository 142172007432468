import useRest from 'hooks/useRest'
import { useState } from 'react'
import {
  TApprovalHierarchy,
  TApprovalHierarchyRequest,
  TApprovalHierarchyResponse,
  TApprovalHierarchyUserRequest,
  TApprovalHierarchyUser,
  TCreateApproverRequest,
  TFindByParamsApprovalHierarchy,
  TUpdateOrderSequenceRequest
} from '.'

const useApprovalHierarchy = () => {
  const [loading, setLoading] = useState(false)
  const rest = useRest()

  const findByFilterApprovalHierarchy = async (
    params: TFindByParamsApprovalHierarchy,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/approval-hierarchy'
    const response = await rest.get(url, params)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TApprovalHierarchyResponse
  }

  const findByIdApprovalHierarchy = async (id: TStringableNumber, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/approval-hierarchy/${id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as TApprovalHierarchy
  }
  const findLogs = async (hierarchy_id: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/audit/approval-hierarchy/logs-update?hierarchy_id=${hierarchy_id}`
    const response = await rest.get(url)
    if (triggerLoading) setLoading(false)
    if (!response) return undefined

    return response.result as any
  }

  const createApprovalHierarchy = async (data: TApprovalHierarchyRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/approval-hierarchy'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TApprovalHierarchy
  }

  const updateApprovalHierarchy = async (data: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/approval-hierarchy'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TApprovalHierarchy
  }
  const updateApprovalHierarchyFormData = async (
      body: any,
      headers?: any,
      triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = '/v1/manager/approval-hierarchy';
    const response = await rest.put(url, body, headers);
    if (triggerLoading) setLoading(false);
    if (!response) throw new Error();

    return response.result as any;
  };

  const createApprover = async (data: TCreateApproverRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/approval-hierarchy/approvers'
    const response = await rest.post(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TApprovalHierarchyUser[]
  }

  const updateApprover = async (data: TApprovalHierarchyUserRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/approval-hierarchy/approvers'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TApprovalHierarchyUser
  }

  const deleteApprover = async (id: TStringableNumber, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = `/v1/manager/approval-hierarchy/approvers/${id}`
    const response = await rest.destroy(url)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as TApprovalHierarchyUser
  }

  const updateOrderSequence = async (data: TUpdateOrderSequenceRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true)
    const url = '/v1/manager/approval-hierarchy/approvers/order'
    const response = await rest.put(url, data)
    if (triggerLoading) setLoading(false)
    if (!response) return

    return response.result as boolean
  }

  return {
    loading,
    findByFilterApprovalHierarchy,
    findByIdApprovalHierarchy,
    createApprovalHierarchy,
    findLogs,
    updateApprovalHierarchy,
    createApprover,
    updateApprover,
    deleteApprover,
    updateApprovalHierarchyFormData,
    updateOrderSequence
  }
}

export default useApprovalHierarchy
