import useRest from "hooks/useRest";
import { useState } from "react";
import {
  TCreateProduct,
  TMeasuringUnit,
  TMeasuringUnitParams,
  TProduct,
  TProductParams,
  TProductResponse,
  TProductType,
  TProductTypeParams,
  TUpdateProduct,
} from "repositories";

const useProduct = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const findProductByFilter = async (
    params: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/product";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TProductResponse;
  };

  const findProductById = async (id: string, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/product/${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as any;
  };

  const createProduct = async (data: TCreateProduct, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/product";
    const response = await rest.post(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result as TProduct;
  };

  const updateProduct = async (data: TUpdateProduct, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/product";
    const response = await rest.put(url, data);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result as TProduct;
  };

  const findProductTypeByFilter = async (
    params?: TProductTypeParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/product/product-type";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result.content as TProductType[];
  };

  const findMeasuringUnitByFilter = async (
    params?: TMeasuringUnitParams,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/product/measuring-units";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result.content as TMeasuringUnit[];
  };

  const findHasProduct = async (id: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/find/has-product/" + id;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response;
  };

  const getStates = async (
    params: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/address/states";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  const getCities = async (
    params: any,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/supplier/address/cities";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result;
  };

  return {
    loading,
    findProductByFilter,
    findProductTypeByFilter,
    findMeasuringUnitByFilter,
    findProductById,
    createProduct,
    updateProduct,
    findHasProduct,
    getStates,
    getCities
  };
};

export default useProduct;
