import React, {useEffect} from 'react'
import Providers from 'providers'
import Routes from 'routes'
import GlobalStyle from 'styles/global'
import './App.css'
import { checkVersion } from "./utils/versionChecker";

const App: React.FC = () => {
    useEffect(() => {
        checkVersion();
        const intervalId = setInterval(checkVersion, 60 * 1000); // Verifica a cada 1 minuto
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Providers>
            <Routes />
            <GlobalStyle />
        </Providers>
    );
};

export default App
