import styled from 'styled-components'

type TMessageProps = {
  alignRight: boolean
}

export const Members = styled.div`
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  transform: translateY(-105%);
  background: white;
  a {
    padding: 10px 15px;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
  }
  `

export const ChatMessage = styled.div<TMessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start')};
  
  margin: 10px;

  span {
    max-width: 60%;
    padding: 10px 15px;
    color: ${({ alignRight, theme }) => (alignRight ? theme.colors.WHITE : theme.colors.BLACK)};
    background-color: ${({ alignRight, theme }) => (alignRight ? theme.colors.GREEN : theme.colors.LIGHT_GRAY)};
    border-radius: ${({ alignRight }) => (alignRight ? '8px 0 8px 8px' : '0 8px 8px 8px')};
    overflow-wrap: break-word;
  }

  p {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: 5px;
   
    time {
      font-size: 11px;
    }
  }
`
