const APP_VERSION = process.env.REACT_APP_VERSION || "dev";

export const checkVersion = (): void => {
    try {
        const currentVersion = localStorage.getItem("appVersion");
        if (currentVersion && currentVersion !== APP_VERSION) {
            console.log(`Nova versão detectada: ${APP_VERSION}. Atualizando...`);

            if ("caches" in window) {
                caches.keys().then((cacheNames) => {
                    cacheNames.forEach((cacheName) => {
                        caches.delete(cacheName);
                    });
                });
            }

            localStorage.setItem("appVersion", APP_VERSION);
            window.location.reload();
        } else {
            console.log(`Versão atual: ${APP_VERSION}`);
            localStorage.setItem("appVersion", APP_VERSION);
        }
    } catch (error) {
        console.error("Erro ao verificar a versão:", error);
    }
};
